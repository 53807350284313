import React from 'react';
import { FormApi } from 'final-form';
import { useDebounce } from 'react-use';
import { noop } from 'lodash';

import { invokeCpretWorker } from '../aws/lambda';

export interface UseApimanAutoFillConfig {
  skip?: boolean;
  sncfCP: string;
  form: FormApi;
  mapper?(key: string): string | null;
  debounceDelay?: number;
}

const defaultMapper = (key: string) => {
  if (
    ![
      'honorificPrefix',
      'givenName',
      'familyName',
      'patronymicName',
      'bday',
      'streetAddress',
      'postalCode',
      'addressLevel2',
      'sncfCompany',
    ].includes(key)
  ) {
    return null;
  }

  switch (key) {
    case 'sncfCompany':
      return key;
    default:
      return `contact.${key}`;
  }
};

export function useApimanAutoFill({
  form,
  skip,
  sncfCP,
  mapper = defaultMapper,
  debounceDelay = 500,
}: UseApimanAutoFillConfig) {
  const [debouncedSncfCp, setDebouncedSncfCp] = React.useState<string | null>(
    null,
  );
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);

  const [, cancel] = useDebounce(
    () => {
      setDebouncedSncfCp(sncfCP);
    },
    debounceDelay,
    [sncfCP],
  );

  React.useEffect(() => {
    if (skip || !debouncedSncfCp) {
      setError(null);

      return cancel;
    }

    if (debouncedSncfCp.length < 7) {
      setError(new Error('Invalid format'));

      return noop;
    }

    setLoading(true);
    invokeCpretWorker(debouncedSncfCp, 'userinfo')
      .then(result => {
        if (!result) {
          setError(new Error('Not Found'));
          return;
        }

        setError(null);
        form.batch(() => {
          Object.keys(result).forEach(key => {
            const target = mapper(key);
            if (!target) {
              return;
            }
            form.change(target, result[key]);
          });
        });
      })
      .catch(e => {
        setError(e);
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });

    return cancel;
  }, [form, skip, debouncedSncfCp, invokeCpretWorker]);

  return { error, loading };
}
