import AWS from 'aws-sdk';

import { awsCreds } from '../auth/authProvider';

export function invoke(params: AWS.Lambda.Types.InvocationRequest) {
  const lambda = new AWS.Lambda({
    apiVersion: '2015-03-31',
    region: 'eu-west-1',
    credentials: awsCreds,
  });

  return lambda.invoke(params).promise();
}

export async function invokeCpretWorker(sncfCP: string, action: string) {
  const body = JSON.stringify({ sncfCP, action });
  const { StatusCode, Payload } = await invoke({
    FunctionName: 'cpret-worker',
    InvocationType: 'RequestResponse',
    Payload: JSON.stringify({ body }),
  });

  if (StatusCode !== 200 || !Payload) {
    return null;
  }

  const response = JSON.parse(Payload.toString());

  if (response.statusCode !== 200) {
    return null;
  }

  return JSON.parse(response.body);
}
